import { FormEventHandler, useState } from "react";
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";

import { FileWithShares } from "src/lib/file";
import { Dialog } from "src/ui/Dialog";
import { useUsers } from "src/queries/useUsers";

type EditFileSharesDialogProps = {
  file: FileWithShares;
  open: boolean;
  onConfirm: (file: { id: number; users: Array<{ id: number }> }) => void;
  onClose: () => void;
};

export function EditFileSharesDialog(props: EditFileSharesDialogProps) {
  const [checked, setChecked] = useState<number[]>(
    props.file.shares.map((s) => s.userId)
  );

  // We do not get the admin users, as they can see all files
  const { data: users } = useUsers({}, { placeholderData: [] });

  const handleToggle = (id: number) => () => {
    const currentIndex = checked.indexOf(id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleClose = () => {
    props.onClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const onSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    handleClose();

    props.onConfirm({
      id: props.file.id,
      users: checked.map((id) => ({ id })),
    });
  };

  // NOTE: This should never happen, as the useUsers query has placeholderData
  if (!users) return null;

  return (
    <Dialog
      open={props.open}
      title="Modifier les droits d'accès"
      cancelText="Annuler"
      confirmText="Modifier"
      onClose={handleClose}
      onCancel={handleCancel}
      onSubmit={onSubmit}
      form
    >
      <List dense sx={{ width: "100%", bgcolor: "background.paper" }}>
        {users
          .filter((u) => u.disabledAt !== null)
          .map((user) => {
            const labelId = `user-${user.id}`;

            return (
              <ListItem
                key={user.id}
                onClick={handleToggle(user.id)}
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={handleToggle(user.id)}
                    checked={checked.indexOf(user.id) !== -1}
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                }
                disablePadding
              >
                <ListItemButton>
                  <ListItemText
                    id={labelId}
                    primary={`${user.firstName} ${user.lastName}`}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
      </List>
    </Dialog>
  );
}
