import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { useWeekDays } from "src/lib/date";
import { getSelectableWorksites, Worksite } from "src/lib/worksite";
import { DurationInput } from "./DurationInput";
import { DurationSelect } from "./DurationSelect";
import { Role } from "src/lib/user";
import { useAuth } from "src/contexts/AuthContext";
import { Dialog } from "src/ui/Dialog";

type CreateJobDialogProps = {
  date: Date;
  dateDisabled?: boolean;
  open: boolean;
  worksites: Worksite[];
  onConfirm: (job: z.infer<typeof CreateJobSchema>) => void;
  onClose: () => void;
};

const CreateJobSchema = z.object({
  duration: z.string().min(1, "Saisir une durée"),
  worksiteId: z.preprocess(
    (value: unknown) => parseInt(value as string),
    z.number().min(1, "Saisir un chantier")
  ),
  description: z.string().optional(),
  date: z.date(),
});

export function CreateJobDialog(props: CreateJobDialogProps) {
  const [selectedWorksiteIndex, setSelectedWorksiteIndex] = useState(0);
  const [exactDuration, setExactDuration] = useState(false);
  const weekDays = useWeekDays();

  const worksites = useMemo(
    () => getSelectableWorksites(props.worksites),
    [props.worksites]
  );

  const selectedWorksite = worksites[selectedWorksiteIndex];

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setError,
    setValue,
  } = useForm<z.infer<typeof CreateJobSchema>>({
    resolver: zodResolver(CreateJobSchema),
    mode: "onSubmit",
    defaultValues: {
      date: props.date,
      worksiteId: selectedWorksite?.id,
    },
  });

  const auth = useAuth();

  // FIXME: What is this used for?
  useEffect(() => {
    props.date && setValue("date", props.date);
  }, [props.date, setValue]);

  const handleClose = () => {
    reset();
    props.onClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const onSubmit = (data: z.infer<typeof CreateJobSchema>) => {
    if (selectedWorksite?.isDescriptionRequired && !data.description) {
      setError("description", {
        type: "required",
        message: "Saisir une description",
      });

      return;
    }

    handleClose();

    props.onConfirm(data);
  };

  const selectedWorksiteId = watch("worksiteId");

  useEffect(() => {
    if (selectedWorksiteId) {
      setSelectedWorksiteIndex(
        worksites.findIndex((worksite) => worksite.id === selectedWorksiteId)
      );
    }
  }, [selectedWorksiteId, worksites]);

  return (
    <Dialog
      open={props.open}
      title="Créer un enregistrement"
      cancelText="Annuler"
      confirmText="Créer"
      onCancel={handleCancel}
      onClose={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      confirmProps={{
        disabled: worksites.length === 0,
      }}
      form
    >
      <FormControl margin="normal" fullWidth>
        <InputLabel id="worksite-label">Chantier</InputLabel>
        <Select
          native
          label="Chantier"
          labelId="worksite-label"
          id="worksite"
          fullWidth
          inputProps={register("worksiteId", {
            required: true,
            // This is required as we use the selected worksite id to find the
            // worksite object. If we do not set this attribute react-hook-form
            // returns a string
            valueAsNumber: true,
          })}
          error={!!errors.worksiteId}
        >
          {worksites.map((worksite) => (
            <option key={worksite.id} value={worksite.id}>
              [{worksite.number}] {worksite.name}
            </option>
          ))}
          {worksites.length === 0 && <option>Aucun chantier</option>}
        </Select>
      </FormControl>
      {selectedWorksite?.isDescriptionRequired && (
        <TextField
          id="description"
          margin="normal"
          label="Description"
          fullWidth
          variant="outlined"
          inputProps={register("description", { required: true })}
          error={!!errors.description}
          helperText={errors.description?.message ?? ""}
        />
      )}
      <Box display="flex" gap={2}>
        {auth.user.role === Role.ADMIN && (
          <FormControlLabel
            control={
              <Checkbox
                checked={exactDuration}
                onChange={(e) => setExactDuration(e.target.checked)}
              />
            }
            label={"Éxacte"}
          />
        )}
        {exactDuration ? (
          <>
            <Controller
              name="duration"
              control={control}
              render={({ field }) => (
                <TextField
                  label="Durée"
                  id="duration"
                  margin="normal"
                  {...field}
                  fullWidth
                  InputProps={{
                    inputComponent: DurationInput as any,
                  }}
                  variant="outlined"
                  error={!!errors.duration}
                  helperText={errors.duration?.message ?? ""}
                />
              )}
            />
          </>
        ) : (
          <FormControl margin="normal" fullWidth>
            <InputLabel id="duration-label" disableAnimation>
              Durée
            </InputLabel>
            <DurationSelect
              label="Durée"
              labelId="duration-label"
              id="duration"
              fullWidth
              defaultValue="00:15"
              inputProps={register("duration", { required: true })}
              error={!!errors.duration}
            />
          </FormControl>
        )}
      </Box>
      {!props.dateDisabled && (
        <Controller
          name="date"
          defaultValue={props.date}
          control={control}
          render={({ field }) => (
            <MobileDatePicker
              {...field}
              label="Date"
              maxDate={weekDays.endDate}
              disableFuture
              slotProps={{
                textField: {
                  id: "date",
                  variant: "outlined",
                  margin: "normal",
                  fullWidth: true,
                  error: !!errors.date,
                  helperText: errors.date?.message,
                },
              }}
            />
          )}
        />
      )}
    </Dialog>
  );
}
