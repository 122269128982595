import { useState } from "react";
import { isSameDay } from "date-fns";
import { Box, Fab, Typography } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

import { ReactComponent as NoDocuments } from "src/svgs/no-documents.svg";

import { useToast } from "src/contexts/ToastContext";
import { CreateJobDialog } from "src/components/CreateJobDialog";
import { TimeTrackingTable } from "src/components/TimeTrackingTable";
import { useWeekDays } from "src/lib/date";
import { useCreateJob } from "src/queries/useCreateJob";
import { useAuth } from "src/contexts/AuthContext";
import { useWorksites } from "src/queries/useWorksites";
import { useUserJobs } from "src/queries/useUserJobs";
import { JobListItem } from "src/components/JobListItem";
import { useUpdateJob } from "src/queries/useUpdateJob";
import { useDeleteJob } from "src/queries/useDeleteJob";
import { useTitle } from "src/hooks/useTitle";
import { getWorksiteName } from "src/lib/worksite";
import { Head } from "src/ui/Head";

type TimeTrackingRouteProps = {
  title: string;
};

export function TimeTrackingRoute({ title }: TimeTrackingRouteProps) {
  useTitle(title);
  const today = new Date();

  const [createJobOpen, setCreateJobOpen] = useState(false);
  const [date, setDate] = useState(today);
  const { showToast } = useToast();
  const auth = useAuth();

  const { startDate, endDate, days } = useWeekDays();

  const { data: worksites } = useWorksites();
  const { data: jobs } = useUserJobs({
    userId: auth.user.id,
    start: startDate,
    end: endDate,
  });

  const createJobMutation = useCreateJob({
    onSuccess() {
      showToast("L'enregistrement a bien été créé");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const updateJobMutation = useUpdateJob({
    onSuccess() {
      showToast("L'enregistrement a bien été modifié");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  const deleteJobMutation = useDeleteJob({
    onSuccess() {
      showToast("L'enregistrement a bien été supprimé");
    },
    onError() {
      showToast("Une erreur est survenue");
    },
  });

  // TOOD: Fix this
  const isOnline = true;

  if (!jobs || !worksites) return null;

  return (
    <>
      <Head title={title} />
      <Fab
        sx={{ position: "fixed", bottom: 36, right: 36, boxShadow: "none" }}
        color="primary"
        onClick={() => setCreateJobOpen(true)}
        disabled={!isOnline}
      >
        <AddIcon />
      </Fab>
      <CreateJobDialog
        open={createJobOpen}
        date={date}
        dateDisabled
        worksites={worksites}
        onClose={() => setCreateJobOpen(false)}
        onConfirm={(data) =>
          createJobMutation.mutate({
            ...data,
            userId: auth.user.id,
          })
        }
      />
      <TimeTrackingTable
        defaultSelectedDate={today}
        onDateChange={setDate}
        onCreateJob={(date) => {
          setDate(date);
          setCreateJobOpen(true);
        }}
        singleDayMode
        jobs={jobs}
        days={days}
        renderDate={(date) => {
          const filteredJobs = jobs.filter((job) => isSameDay(job.date, date));

          if (filteredJobs.length === 0) {
            return (
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  flexFlow: "column nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
                // onContextMenu={contextMenu.onContextMenu}
              >
                <NoDocuments style={{ marginTop: -64 }} />
                <Typography variant="h6" color="text.primary">
                  Aucun enregistrement
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  textAlign="center"
                  marginTop={1}
                  sx={{ maxWidth: 300 }}
                >
                  Appuyez sur le bouton en bas à droite pour créer un
                  enregistrement
                </Typography>
              </Box>
            );
          }

          return (
            <>
              {filteredJobs.map((job) => (
                <JobListItem
                  key={job.id}
                  job={job}
                  primary={getWorksiteName(job.worksite)}
                  worksites={worksites}
                  onUpdate={updateJobMutation.mutate}
                  onDelete={deleteJobMutation.mutate}
                />
              ))}
            </>
          );
        }}
      />
    </>
  );
}
